<template>
    <a-card :bordered="false">
      <div class="">
        <a-form layout="inline" label-align="left">
          <a-row :gutter="48">
            <a-col :md="8">
              <a-form-item label="发布时间">
                <a-space direction="vertical" :size="12">
                    <a-range-picker @change="opDateChange" />
                </a-space>
              </a-form-item>
            </a-col>

            <a-col :md="8">
                <a-form-item label="审核时间">
                  <a-space direction="vertical" :size="12">
                    <a-range-picker @change="permDateChange" />
                  </a-space>
                </a-form-item>
              </a-col>

            <a-col :md="8">
              <a-form-item label="发布人">
                <a-input v-model="queryParam.publish_user" placeholder="请输入"/>
              </a-form-item>
            </a-col>

            <a-col :md="8">
              <a-form-item label="审核人">
                <a-input v-model="queryParam.phone" placeholder="请输入"/>
              </a-form-item>
            </a-col>
            <a-col :md="8">
              <a-form-item label="类型">
                <a-select v-model="queryParam.type" style="width: 120px">
                  <a-select-option :value="0">全部</a-select-option>
                  <a-select-option :value="1">随意记</a-select-option>
                  <a-select-option :value="2">科学记</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8">
              <a-form-item label="审核状态">
                <a-select v-model="queryParam.perm_status" style="width: 120px">
                  <a-select-option :value="0">全部</a-select-option>
                  <a-select-option :value="1">启用</a-select-option>
                  <a-select-option :value="2">停用</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
        

            <a-col :md="8">
              <a-form-item label="备注">
                <a-input v-model="queryParam.phone" placeholder="请输入"/>
              </a-form-item>
            </a-col>

            <a-col :md="8">
              <span>
                <a-button type="primary" @click="searchLoad">搜索</a-button>
              </span>
            </a-col>
            
            <a-col :md="8">
              <a-form-item label="开启自动通过">
                 <a-switch :checked="sysConfig && sysConfig.auto_approved == 1" @change="autoApproved" />
              </a-form-item>
            </a-col>

          </a-row>
        </a-form>
      </div>

      <a-table
        size="default"
        rowKey="id"
        :columns="columns"
        :dataSource="data"
        :alert="true"
        :showPagination="false"
        :pagination="false"
      >
        <div slot="img" slot-scope="text">
          <div v-if="text.type == 'image'">
            <img
              :width="100"
              :height="100"
              :src="text.url"
            />
          </div>
          <div v-else style="height: 100px">
            <video-player
              class=""
              ref="videoPlayer"
              :options="{
          height: '100px',width: '150px',sources: [{type: 'video/mp4',src: text.url,}]
}"
            >
          </video-player>
          </div>

        </div>
        

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="showDetail(record)">详情</a>
            <a-divider type="vertical" />
            <a @click="handleStartUse(record)">通过</a>
            <a-divider type="vertical" />
            <a @click="handleStopUse(record)">驳回</a>
            <a-divider type="vertical" />
            <a @click="addRemark(record)">备注</a>
          </template>
        </span>
      </a-table>
<a-pagination :defaultPageSize="10" :total="total" @change="loadMore" />
      <a-modal
        title="添加备注"
        :visible="remarkVisible"
        @ok="handleRemarkOk"
        @cancel="handleRemarkCancel"
      >
        <a-textarea
          v-model="opRemarks"
          placeholder=""
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-modal>
      

      <a-modal
        :destroyOnClose="true"
        :forceRender="true"
        class="record-detail"
        v-model="modelVisible"
        title="详情"
        centered
        @ok="() => (modelVisible = false)"
      >
        <p>头像: <a-avatar :src="detailRecord.avatar_url" /></p>
        <p>昵称: {{detailRecord.publish_user}}</p>
        <p>位置: {{detailRecord.location.name}}</p>
        <p>物种名: {{detailRecord.bird_name}}</p>
        <p>拉丁名: {{detailRecord.la_name}}</p>
        <p>英文名: {{detailRecord.en_name}}</p>
        <p>数量: {{detailRecord.num}}</p>
        <p>目: {{detailRecord.mu}}</p>
        <p>科: {{detailRecord.ke}}</p>
        <p>图片/视频: </p>
        <div v-if="detailRecord.media.length > 0 && !previewVisible" class="media-list">
          <div v-for="(item, index) in detailRecord.media" :key="index" class="pre-item">
            <img
                v-if="item.type == 'image'"
                :height="100"
                :src="item.url"
                @click="handlePreview(item.url)"       
            />
            <video-player
                v-else
                class=""
                ref="videoPlayer1"
                :options="{
          height: '100px',width: '150px',sources: [{type: 'video/mp4',src: item.url,}]
}"
              >
            </video-player>

        <!-- let playerOptions = {
          height: "100px",width: "150px",sources: [{type: "video/mp4",src: url,}]
} -->
          </div>
        </div>
        <p>备注: {{detailRecord.desc}}</p>
      </a-modal>
<!-- style="position:relative;"  -->
      <a-modal :z-index=10000 class="preview-img" :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </a-card>
</template>

<script>
import request from '@/utils/request'

const columns = [
  {
    title: '序号',
    dataIndex: "id"
  },
    {
    title: '图片',
    dataIndex: 'img',
     scopedSlots: { customRender: 'img' }
  },
  {
    title: '内容',
    dataIndex: 'content'
  },
  {
    title: '类型',
    dataIndex: 'type',
  },
  {
    title: '发布时间',
    dataIndex: 'created_at',
  },
    {
    title: '发布人',
    dataIndex: 'publish_user',
  },
   {
    title: '审核状态',
    dataIndex: 'status',
  },
    {
    title: '审核人',
    dataIndex: 'op_name',
  },
    {
    title: '审核时间',
    dataIndex: 'op_at',
  },  
    {
    title: '备注',
    dataIndex: 'op_remarks',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]


export default {
  name: 'User',
  components: {
  },
  data () {
    this.columns = columns
    return {
    pagination: {
    total:0,
    defaultPageSize:10
    },
    previewVisible: false,
    previewImage: '',
    remarkVisible: false,
    remarkId: 0,
    opRemarks: '',
     modelVisible: false,
     sysConfig: null,
     detailRecord: {
      avatar_url: '',
      nickname: '',
      location: {name: ''},
      bird_name: '',
      la_name: '',
      en_name: '',
      num: 0,
      mu: '',
      ke: '',
      desc: '',
      op_remarks: '',
      media: []
     },
      data: [],
      total: 0,
      // 查询参数
      queryParam: {
        page: 1,
        limit: 10,
        create_start_at: '',
        create_end_at: '',
        phone: '',
        type: 0,
        perm_status: 0,
        permed_start_at: '',
        permed_end_at: '',
        publish_user: '',
      }
    }
  },
  filters: {

  },
  created () {
    this.loadData()
    this.loadConfig()
  },
  computed: {
  },
  methods: {
  loadMore(e){
  this.queryParam.page = e
    this.loadData()
  },
    searchLoad() {
  this.queryParam.page = 1
  this.loadData()
  },
    loadConfig() {
     request({
        url: "/config",
        method: 'get'
      }).then(res => {
        this.sysConfig = res.data
      })
    },
    loadData() {
      request({
        url: "/record/list",
        method: 'get',
        params: this.queryParam
      }).then(res => {
        this.data = res.data.list
        this.total = res.data.total
        this.pagination.total = res.data.total
      })
    },
    showDetail(record) {
      this.detailRecord = record
      this.modelVisible = true;
    },
    addRemark(record) {
      console.log(record)
      this.remarkVisible = true
      this.remarkRecord = record
      this.remarkId = record.id
      this.opRemarks = ''
    },
    handleStopUse(record) {
      request({
        url: "/record/op",
        method: 'post',
        data: {
          id: record.id,
          status: 2,
          sys_user: 'xiaoming'
        }
      }).then(res => {
      console.log(res)
        if (res.status == 1) {
            location.reload()
        }
      })
    },

    handleStartUse (record) {
      request({
        url: "/record/op",
        method: 'post',
        data: {
          id: record.id,
          status: 1,
          sys_user: 'xiaoming'
        }
      }).then(res => {
        if (res.status == 1) {
            location.reload()
        }
      })
    },

    opDateChange(data, dataString) {
      console.log(data, dataString)
      this.queryParam.create_start_at = dataString[0]
      this.queryParam.create_end_at = dataString[1]
    },

    permDateChange(data, dataString) {
    console.log(data, dataString)
      this.queryParam.permed_start_at = dataString[0]
      this.queryParam.permed_end_at = dataString[1]
    },

    showConfirm() {
      var that = this
      this.$confirm({
        title: '确定要开启吗',
        content: '开启自动审核后，用户发起的观察记录将会被自动审核通过',
        onOk() {
            request({
            url: "/config/autoapproved",
            method: 'post',
            data: {
              sys_user: 'xiaoming'
            }
          }).then(res => {
            if (res.status == 1) {
              that.sysConfig = res.data
              that.loadData()
            }
          })
        },
        onCancel() {}
      });
    },

    autoApproved(checked) {
      console.log(checked)
      if (checked) {
        // confirm
        this.showConfirm()
      } else {
        request({
          url: "/config/autoapproved",
          method: 'post',
          data: {
            sys_user: 'xiaoming'
          }
        }).then(res => {
          if (res.status == 1) {
            this.sysConfig = res.data
            location.reload()
          }
        })
      }
    },

    handleRemarkOk(e) {
      console.log(e)
      this.remarkVisible = false
      request({
        url: "/record/remark",
        method: 'post',
        data: {
          id: this.remarkId,
          content: this.opRemarks
        }
      }).then(res => {
        console.log(res)
        location.reload()
      })
      this.remarkId = 0
      this.opRemarks = ''
    },
    handleRemarkCancel(e) {
      console.log(e)
      this.remarkVisible = false
      this.remarkId = 0
      this.opRemarks = '' 
    },


      // listen event
      onPlayerPlay(player) {
        console.log('player play!', player)
      },
      onPlayerPause(player) {
        console.log('player pause!', player)
      },
      onPlayerEnded(player) {
        console.log('player ended!', player)
      },
      onPlayerLoadeddata(player) {
        console.log('player Loadeddata!', player)
      },
      onPlayerWaiting(player) {
        console.log('player Waiting!', player)
      },
      onPlayerPlaying(player) {
        console.log('player Playing!', player)
      },
      onPlayerTimeupdate(player) {
        console.log('player Timeupdate!', player.currentTime())
      },
      onPlayerCanplay(player) {
        console.log('player Canplay!', player)
      },
      onPlayerCanplaythrough(player) {
        console.log('player Canplaythrough!', player)
      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
        console.log('player current update state', playerCurrentState)
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player)
        player.currentTime(10)
        // console.log('example 01: the player is readied', player)
      },
      handlePreview(url) {
        this.previewImage = url;
        this.previewVisible = true;
        // this.modelVisible=false
      },
      handleCancel() {
        this.previewVisible = false;
        // this.modelVisible=true
      },

      getPlayerOptions (url) {
        console.log(url)
        let playerOptions = {
          height: "100px",
          width: "150px",
          // fluid: true,
          sources: [{
            type: "video/mp4",
            // mp4
            src: url,
            // webm
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          }]
        }

        return playerOptions
      }
  }
}
</script>

<style>
.media-list {
  display: flex;
  flex-wrap: wrap;
}
.media-list .pre-item {
  width: 32%;
  height: 100px;
  margin-bottom: 20px;
}
.media-list .pre-item:not(:nth-child(3n)) {
  margin-right: 5px;
}
.media-list .pre-item .vjs-paused {
}
.pre-item img {
  width: 100%;
}
.media-list .pre-item .video-js {
  height: 100px;
  width: 100%;
}
</style>
